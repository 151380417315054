import React, { Component } from 'react';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes';
import * as firebase from "firebase/app";
import "firebase/firestore";

class App extends Component {
  constructor(props) {
    super(props);
    
  }
  render() {
   return (
    <div>
      <MyRouts />
    </div>
    );   
  }

}

export default App;
