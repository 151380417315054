import React, { Component } from 'react';

const initData = {
    heading: "People don't know what they want",
    headingTwo: "until you show it to them...",
    headingThree: "Our task is to read things",
    headingFour: "that are not yet on the page",
    content: "-- Steve Jobs",
    btnText: "Keep me posted",
    iconClass: "fas fa-play"
}

class HeroSection extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12">
                            <div className="welcome-intro">
                                <h2 className="text-white d-none d-lg-block">&ldquo;{this.state.data.heading}
                                    <br />{this.state.data.headingTwo}
                                    <br />{this.state.data.headingThree}
                                    <br />{this.state.data.headingFour} &rdquo;                                  
                                </h2>
                                <h3 className="text-white d-lg-none">&ldquo;{this.state.data.heading}
                                    <br />{this.state.data.headingTwo}
                                    <br />{this.state.data.headingThree}
                                    <br />{this.state.data.headingFour} &rdquo;                                  
                                </h3>
                                <h4 className="text-white my-4 offset-6">{this.state.data.content}</h4>
                                {/* Subscribe Form */}
                                <form
          ref={this.myForm}
          // onSubmit={this.submitHangler}
          className="contact-form"
          noValidate="novalidate"
          action="mailto:info@preemptive.tech?subject=Please keep me posted"
          method="post" 
          encType="text/plain"
        >
                                <div className="subscribe-form d-flex align-items-center">

                                    <input type="email" className="form-control" placeholder="info@yourmail.com" />
                                    <button type="submit" className="btn btn-bordered" >{this.state.data.btnText}</button>
                                    
                                </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape-bottom">
                    <svg fill="#fff" id="Layer_1" x="0px" y="0px" viewBox="0 0 1921 819.8" style={{enableBackground: 'new 0 0 1921 819.8'}} xmlSpace="preserve">
                    <path className="st0" d="M1921,413.1v406.7H0V0.5h0.4l228.1,598.3c30,74.4,80.8,130.6,152.5,168.6c107.6,57,212.1,40.7,245.7,34.4  c22.4-4.2,54.9-13.1,97.5-26.6L1921,400.5V413.1z" />
                    <path className="st1" d="M1921,387.9v431.9H0V0h13l226.6,594.4c14.4,35.7,34,67.4,58.3,94.3c24.4,26.9,54.2,49.8,88.6,68.1  c50.2,26.6,105.8,40.1,165.2,40.1c36.1,0,63.6-5.2,72.6-6.9c21.6-4,53.9-12.9,96.1-26.2L1921,387.9z" />
                    </svg>
                </div>
            </section>
        );
    }
}

export default HeroSection;